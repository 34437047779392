import { InputBaseComponentProps } from '@material-ui/core';
import {
    AdminRole,
    IAccount,
    IAccountSettingsForm,
    IAdminPanelUiProps,
    ICheckoutForm,
    IContactForm,
    IDeliveryCostForm,
    ILocalityForm,
    IProductForm,
    IRegionForm,
    IResetPasswordForm,
    ISearchForm,
    IServiceForm,
    ISidebarProps,
    ISignUpForm,
    IUiProps,
    IUser,
    IUserForm,
    OrderStatus,
} from '../../../types';

export function setSearchForm(searchForm: ISearchForm): ISearchForm {
    return {
        region: searchForm.region,
        locality: searchForm.locality,
        name: searchForm.name,
        category: searchForm.category,
        deliveryMethod: searchForm.deliveryMethod,
        sellerId: searchForm.sellerId,
        material:
            searchForm.category === 'Polttopuut' && searchForm.material
                ? searchForm.material
                : searchForm.category === 'Polttopuut'
                ? 'all'
                : '',
        quality:
            searchForm.category === 'Polttopuut' && searchForm.quality
                ? searchForm.quality
                : searchForm.category === 'Polttopuut'
                ? 'all'
                : '',
    };
}
export function validateUserRole(role: AdminRole) {
    if (role === 'admin' || role === 'root') {
        return true;
    } else {
        return false;
    }
}
export function manageSelectedLocationCheckboxes(
    value: string,
    deliveryLocationsSelections: string[],
): string[] {
    const newArray = [
        ...(deliveryLocationsSelections ? deliveryLocationsSelections : []),
    ];

    newArray.includes(value)
        ? newArray.splice(newArray.indexOf(value), 1)
        : newArray.push(value);
    return newArray;
}
// set pricetag function for views
export function setPriceTag(price: number) {
    let formattedPrice;
    const sPrice = String(price);
    const nLength = (a: number) => {
        let e = 1;
        while (Math.round(a * e) / e !== a) {
            e *= 10;
        }
        return Math.log(e) / Math.LN10;
    };
    formattedPrice = `${
        Number.isInteger(price)
            ? `${price},00 €`
            : nLength(price) === 2
            ? `${parseFloat(sPrice).toFixed(2)} €`
            : nLength(price) > 2
            ? `${parseFloat(sPrice).toFixed(2)} €`
            : `${price}0 €`
    }`;
    return formattedPrice;
}
export function getAccountsPrice(account: IAccount): number | string {
    return account.discountedPrice !== undefined && account.discountedPrice > 0
        ? account.discountedPrice
        : setPriceTag(account.price);
}
export function setAccountOfferText(account: IAccount): string {
    return account.discountedPrice !== undefined && account.discountedPrice > 0
        ? 'Normaalisti ' + account.price + '0 €/vuosi'
        : 'vuosittain uusiutuva tilaus.';
}
export function getProductName(item: any): string {
    if (item.name && item.material) {
        return item.name + ' ' + item.material;
    } else {
        return item.name;
    }
}
export function resolveStatus(status: OrderStatus): string {
    return status === 'recieved'
        ? 'Vastaanotettu'
        : status === 'in progress'
        ? 'Käsittelyssä'
        : status === 'in delivery'
        ? 'Toimituksessa'
        : 'Toimitettu';
}
export function setInputValues(event: InputBaseComponentProps) {
    return {
        [event.target.name]:
            event.target.name === 'contactByPhone' &&
            event.target.value === 'false'
                ? false
                : event.target.name === 'contactByPhone' &&
                  event.target.value === 'true'
                ? true
                : event.target.name === 'business' &&
                  event.target.value === 'false'
                ? false
                : event.target.name === 'business' &&
                  event.target.value === 'true'
                ? true
                : event.target.name !== 'terms'
                ? event.target.value
                : event.target.value === 'false'
                ? true
                : false,
    } as Pick<ISignUpForm, keyof ISignUpForm>;
}
export function setUserFormInputValues(event: InputBaseComponentProps) {
    return {
        [event.target.name]:
            event.target.name === 'contactByPhone' &&
            event.target.value === 'false'
                ? false
                : event.target.name === 'contactByPhone' &&
                  event.target.value === 'true'
                ? true
                : event.target.name === 'business' &&
                  event.target.value === 'false'
                ? false
                : event.target.name === 'business' &&
                  event.target.value === 'true'
                ? true
                : event.target.name !== 'terms'
                ? event.target.value
                : event.target.value === 'false'
                ? true
                : false,
    } as Pick<IUserForm, keyof IUserForm>;
}
export function setAccountSettingsFormInputValues(
    event: InputBaseComponentProps,
) {
    return {
        [event.target.name]:
            event.target.name !== 'locality' ? event.target.value : '',
    } as Pick<IAccountSettingsForm, keyof IAccountSettingsForm>;
}
export function setCheckoutFormInputValues(event: InputBaseComponentProps) {
    return {
        [event.target.name]:
            event.target.name !== 'terms'
                ? event.target.value
                : event.target.value === 'false'
                ? true
                : false,
    } as Pick<ICheckoutForm, keyof ICheckoutForm>;
}

export function setResetPasswordInputValues(event: InputBaseComponentProps) {
    return {
        [event.target.name]: event.target.value,
    } as Pick<IResetPasswordForm, keyof IResetPasswordForm>;
}
export function setSearchInputValues(event: InputBaseComponentProps) {
    return {
        [event.target.name]:
            event.target.name !== 'discount'
                ? event.target.value
                : event.target.value === 'false'
                ? true
                : false,
    } as Pick<ISearchForm, keyof ISearchForm>;
}
export function setDeliveryCostFormInputValues(event: InputBaseComponentProps) {
    return {
        [event.target.name]:
            event.target.name !== 'available' &&
            event.target.name !== 'multipliedByQuantity'
                ? event.target.value
                : event.target.value === 'true'
                ? false
                : true,
    } as Pick<IDeliveryCostForm, keyof IDeliveryCostForm>;
}
export function setProductCreationFormInputValues(
    event: InputBaseComponentProps,
) {
    return {
        [event.target.name]:
            event.target.name !== 'discount'
                ? event.target.value
                : event.target.value === 'false'
                ? true
                : false,
    } as Pick<IProductForm, keyof IProductForm>;
}
export function setServiceCreationFormInputValues(
    event: InputBaseComponentProps,
) {
    return {
        [event.target.name]: event.target.value,
    } as Pick<IServiceForm, keyof IServiceForm>;
}
export function setRegionFormInputValues(event: InputBaseComponentProps) {
    return {
        [event.target.name]: event.target.value,
    } as Pick<IRegionForm, keyof IRegionForm>;
}
export function setLocalityFormInputValues(event: InputBaseComponentProps) {
    return {
        [event.target.name]: event.target.value,
    } as Pick<ILocalityForm, keyof ILocalityForm>;
}
export function setContactFormInputValues(event: InputBaseComponentProps) {
    return {
        [event.target.name]: event.target.value,
    } as Pick<IContactForm, keyof IContactForm>;
}
export function setUiPropsValues(
    key: string,
    value: boolean | number | undefined | string,
) {
    return {
        [key]: value,
    } as Pick<IUiProps, keyof IUiProps>;
}
export function setAdminPanelUiStateValues(
    key: string,
    value: string | boolean | number | undefined,
) {
    return {
        [key]: value,
    } as Pick<IAdminPanelUiProps, keyof IAdminPanelUiProps>;
}
export function setSideBarValues(key: string, value: boolean) {
    return {
        [key]: value,
    } as Pick<ISidebarProps, keyof ISidebarProps>;
}

export function setSellerName(owner: IUser): string {
    if (owner.companyName) {
        return owner.companyName;
    }
    return (
        owner.contactInformation.firstname +
        ' ' +
        owner.contactInformation.lastname
    );
}
